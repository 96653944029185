import React, { useEffect, useState } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import LoaderButton from '../LoaderButton';
import { Items, EditOrderMutation } from '../../libs/GraphQL';
import styledComponent from '../../utils/styledComponent';
import ErrorsBlock from '../../atoms/ErrorsBlock';
import FileUpload from '../../atoms/FileUpload';
import { uploadFileToS3 } from '../../libs/uploadToS3';

const LogModalTitle = styledComponent('h4', {
  borderBottom: '1px solid #e5e5e5',
  padding: '15px',
  margin: '0px',
});

const allowedFileColumns = [
  'Order ID',
  'Item Name',
  'Count',
  'Production Date',
  'Delivery Date',
  'JIT Eligible',
  'Rebalance Eligible',
];

const notReqFileColumns = ['Drop Off Date', 'Address', 'LH Departure Date'];

const OrderFridgeMassUpdate = () => {
  const [fileContent, setFileContent] = useState([]);
  const [allowedItemsNames, setAllowedItemsNames] = useState(null);
  const [fileErrors, setFileErrors] = useState([]);
  const [lineErrors, setLineErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileChosen, setFileChosen] = useState(false);
  const [fileName, setFileName] = useState('');
  const [alertContent, setAlertContent] = useState([]);
  const [fileColumns, setFileColumns] = useState([]);

  const isNumber = (value) => !Number.isNaN(Number(value));

  const hasErrors = fileErrors.length > 0 || lineErrors.length > 0;

  const getVendItems = async () => {
    try {
      const itemsNames = [];
      const rawVendItems = await Items();
      const activeVendItems = rawVendItems.data.items.filter(
        (item) => item.status === 'LIVE',
      );
      activeVendItems.forEach((item) => {
        itemsNames.push(item.id);
      });
      setAllowedItemsNames(itemsNames);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getVendItems();
  }, []);

  const validateFile = (file) => {
    const errors = [];
    const fileHeaders = file.meta.fields;
    setFileColumns(fileHeaders);

    const columnsToValidate = fileHeaders.filter(
      (item) => !notReqFileColumns.includes(item),
    );

    const missedColumns = allowedFileColumns.filter(
      (x) => !columnsToValidate.includes(x),
    );
    const unknownColumns = columnsToValidate.filter(
      (x) => !allowedFileColumns.includes(x),
    );

    if (missedColumns.length) {
      errors.push(`Missed columns: ${missedColumns.join(', ')}`);
    }

    if (unknownColumns.length) {
      errors.push(
        `Unknown columns: ${unknownColumns.join(
          ', ',
        )}. File should contains following columns: ${allowedFileColumns.join(
          ', ',
        )}`,
      );
    }

    return errors;
  };

  const itemDict = {};

  const validateLine = (line) => {
    const errors = [];
    const today = moment().format('YYYY-MM-DD');

    // Validate `Order ID` column
    if (line['Order ID'] === '') {
      errors.push('Value required for `Order ID` column');
    }

    // Validate `Item Name` column
    if (line['Item Name'] === '') {
      errors.push('Value required for `Item Name` column');
    } else if (!allowedItemsNames.includes(line['Item Name'])) {
      errors.push('SKU in `Item Name` column does not match active SKU list');
    }

    // Validate duplicate `item`
    if (!itemDict[line['Order ID']]) {
      itemDict[line['Order ID']] = [];
    }
    if (itemDict[line['Order ID']].includes(line['Item Name'])) {
      errors.push(
        'Duplicate item: ' +
          line['Item Name'] +
          ' in OrderID: ' +
          line['Order ID'] +
          ' remove and re-upload.',
      );
    } else {
      itemDict[line['Order ID']].push(line['Item Name']);
    }

    // Validate `Count` column
    if (line.Count === '') {
      errors.push('Value required for `Count` column');
    } else if (!isNumber(line.Count)) {
      errors.push('`Count` must be a number');
    } else if (line.Count < 1) {
      errors.push('`Count` must have quantity greater than 0');
    }

    // Validate `Production Date` column
    const productionDateMoment = moment(
      line['Production Date'],
      'YYYY-MM-DD',
      true,
    );

    if (line['Production Date'] === '') {
      errors.push('Value required for `Production Date` column');
    } else if (!productionDateMoment.isValid()) {
      errors.push(
        '`Production Date` contains invalid date. Date should be in `YYYY-MM-DD` format',
      );
    }

    // Validate `Delivery Date` column
    const deliveryDateMoment = moment(
      line['Delivery Date'],
      'YYYY-MM-DD',
      true,
    );

    if (line['Delivery Date'] === '') {
      errors.push('Value required for `Delivery Date` column');
    } else if (!deliveryDateMoment.isValid()) {
      errors.push(
        '`Delivery Date` contains invalid date. Date should be in `YYYY-MM-DD` format',
      );
    } else if (deliveryDateMoment.isBefore(productionDateMoment)) {
      errors.push('`Delivery Date` must come after `Production Date`');
    } else if (deliveryDateMoment.isSameOrBefore(today)) {
      errors.push('`Delivery Date` can not be before today');
    }

    // Validate `JIT Eligible` column
    if (line['JIT Eligible' === '']) {
      errors.push('Value required for `JIT Eligible` column');
    } else if (
      !(line['JIT Eligible'] === true || line['JIT Eligible'] === false)
    ) {
      errors.push(
        '`JIT Eligible` contains invalid value. Accepted values are true or false',
      );
    }

    // Validate `Rebalance Eligible` column
    if (line['Rebalance Eligible' === '']) {
      errors.push('Value required for `Rebalance Eligible` column');
    } else if (
      !(
        line['Rebalance Eligible'] === true ||
        line['Rebalance Eligible'] === false
      )
    ) {
      errors.push(
        '`Rebalance Eligible` contains invalid value. Accepted values are true or false',
      );
    }

    // Validate `LH Departure Date` column
    if (
      line['LH Departure Date'] !== undefined &&
      line['LH Departure Date'] !== ''
    ) {
      const lhDepartureDateMoment = moment(
        line['LH Departure Date'],
        'YYYY-MM-DD',
        true,
      );

      if (!lhDepartureDateMoment.isValid()) {
        errors.push(
          '`LH Departure Date` contains invalid date. Date should be in `YYYY-MM-DD` format',
        );
      } else if (
        lhDepartureDateMoment.isBefore(moment().format('YYYY-MM-DD'))
      ) {
        errors.push('`LH Departure Date` cannot be in the past');
      }
    }

    return errors;
  };

  const handleFileChange = (file) => {
    setFileErrors([]);
    setLineErrors([]);
    setFileChosen(false);
    setFileContent([]);
    setFileName(file.name);
    if (file.type !== 'text/csv') {
      setFileErrors(['File must be a .csv']);
    } else {
      window.Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (fileData) => {
          const fileValidationErrors = validateFile(fileData);
          if (fileValidationErrors.length) {
            setFileErrors(fileValidationErrors);
          } else {
            const linesValidationErrors = [];
            fileData.data.forEach((line, i) => {
              const lineValidationErrors = validateLine(line);
              if (lineValidationErrors.length) {
                linesValidationErrors.push({
                  location: `[Line ${i + 2}]`,
                  data: lineValidationErrors,
                });
              }
            });

            if (!linesValidationErrors.length) {
              setFileContent(fileData);
            } else {
              setLineErrors(linesValidationErrors);
            }
          }
        },
      });
    }
    setFileChosen(true);
  };
  const uploadFile = async () => {
    try {
      const uploadResult = await uploadFileToS3(
        fileName,
        'mass-fridge-bulk-update',
      );
      // Handle successful upload
    } catch (error) {
      // Handle any errors during the upload
    }
  };

  const handleUpload = async () => {
    setLoading(true);
    const alertMessages = [];
    const ordersToEdit = Object.values(
      fileContent.data.reduce((acc, curr) => {
        if (!acc[curr['Order ID']]) {
          acc[curr['Order ID']] = {
            uniqueId: curr['Order ID'],
            deliveryDate: curr['Delivery Date'],
            lhDepartureDate: curr['LH Departure Date'] || null,
            itemsCount: [],
            jitEligible: [],
            rebalanceEligible: [],
          };
        }

        acc[curr['Order ID']].itemsCount.push({
          itemName: curr['Item Name'],
          count: curr.Count,
          productionDate: curr['Production Date'],
        });

        if (curr['JIT Eligible']) {
          acc[curr['Order ID']].jitEligible.push(curr['Item Name']);
        }

        if (curr['Rebalance Eligible']) {
          acc[curr['Order ID']].rebalanceEligible.push(curr['Item Name']);
        }

        return acc;
      }, {}),
    );

    let storeToS3 = true;
    // eslint-disable-next-line no-restricted-syntax
    for (const item of ordersToEdit) {
      console.log('ordersToEdit', ordersToEdit);
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await EditOrderMutation({
          uniqueId: item.uniqueId,
          itemsCount: item.itemsCount,
          deliveryDate: item.deliveryDate,
          ...(item.lhDepartureDate && {
            lhDepartureDate: item.lhDepartureDate,
          }),
          jitAllocationItems: item.jitEligible,
          orderRebalanceItems: item.rebalanceEligible,
          modifiedBy: 'ORDER_MASS_UPDATE',
        });
        if (response.data.editOrder.code === 500) {
          throw new Error(
            `Error: ${item.uniqueId} was NOT updated. Please submit the entire file again`,
          );
        } else {
          alertMessages.push(`${item.uniqueId} successfully updated`);
        }
      } catch (e) {
        storeToS3 = false;
        if (e.message !== '') {
          alertMessages.push(e.message);
        } else {
          alertMessages.push(
            `Error: ${item.uniqueId} Unable to update order, Please check your internet and your permissions.`,
          );
        }
      }
    }
    if (storeToS3) {
      uploadFile();
    }
    setAlertContent(alertMessages);
    setFileContent([]);
    setFileChosen(false);
    setLoading(false);
  };

  return (
    <>
      <h3>Fridge</h3>

      {fileChosen ? (
        <Button
          block
          bsStyle="primary"
          bsSize="large"
          style={{ marginBottom: '40px' }}
          disabled={loading}
          onClick={() => {
            setFileErrors([]);
            setLineErrors([]);
            setFileChosen(false);
            setFileContent([]);
          }}
        >
          Upload another file
        </Button>
      ) : (
        <FileUpload onFileChange={handleFileChange} />
      )}

      {hasErrors && (
        <ErrorsBlock>
          <ul>
            {fileErrors.map((error) => {
              return <li key={error}>{error}</li>;
            })}
            {lineErrors.map((error) => {
              return (
                <li key={error.location}>
                  <b>{error.location} has an error</b> <br />
                  {error.data.map((msg) => {
                    return <div key={msg}> {msg} </div>;
                  })}
                </li>
              );
            })}
          </ul>
        </ErrorsBlock>
      )}

      {fileContent.length !== 0 && !hasErrors && (
        <>
          <span>{fileName}</span>
          <Table>
            <thead style={{ border: '1px solid #ddd' }}>
              <tr>
                {fileColumns.map((col) => {
                  return <th key={col}>{col}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {fileContent &&
                fileContent.data.map((line) => {
                  return (
                    <tr key={line['Order ID'] + line['Item Name']}>
                      {fileColumns.map((col) => {
                        return (
                          <td key={col}>
                            {typeof line[col] === 'boolean'
                              ? line[col].toString()
                              : line[col]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <LoaderButton
            block
            bsStyle="success"
            bsSize="large"
            type="submit"
            isLoading={loading}
            text="Run Order Mass Update"
            loadingText="Orders Updating…"
            style={{ marginBottom: '40px' }}
            onClick={handleUpload}
          />
        </>
      )}

      <Modal show={alertContent.length}>
        <LogModalTitle>Order Mass Update Log</LogModalTitle>
        <Modal.Body>
          {alertContent.map((msg) => (
            <div key={msg} style={{ marginBottom: '8px' }}>
              {msg}
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="warning"
            onClick={() => {
              setAlertContent([]);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderFridgeMassUpdate;
